@charset "UTF-8";
/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
._3UmJ3qFSEG8HRfAGE8oX73 {
  -ms-flex-align: center;
      align-items: center;
  min-height: 48px;
  height: 48px;
  max-height: 48px;
  -ms-flex-pack: start;
      justify-content: flex-start;
  border-bottom: 1px solid #c0c0c0; }
  ._3UmJ3qFSEG8HRfAGE8oX73._1A50WjnTFMXiDyiDHwy4gS {
    height: 100vh;
    z-index: 13; }
  ._3UmJ3qFSEG8HRfAGE8oX73 ._2gBrz072DVyIhVmDIQTvLs {
    position: relative;
    -ms-flex-preferred-size: 20px;
        flex-basis: 20px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: url("assets/icon-search.svg") center no-repeat;
    background-size: 20px 20px;
    z-index: 102; }
    ._3UmJ3qFSEG8HRfAGE8oX73 ._2gBrz072DVyIhVmDIQTvLs._2o-28qLH_z4aODZ_gvpgnQ {
      position: fixed;
      width: 35px;
      height: 20px;
      top: 15px;
      right: 0;
      background: none; }
      ._3UmJ3qFSEG8HRfAGE8oX73 ._2gBrz072DVyIhVmDIQTvLs._2o-28qLH_z4aODZ_gvpgnQ::before {
        content: '取消';
        position: absolute;
        left: 0;
        right: 0;
        top: 4px;
        bottom: 0; }

._3lyej5loQpNAWYxJr58Tke {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  z-index: 10; }

._1Ludo-mEssWFa5ttSqlC0h {
  width: 85px;
  height: 28px;
  margin-left: 21px;
  background: url("assets/anue-logo.svg") center no-repeat;
  background-size: 100% 100%; }

._2B8frl-mEyogqPIx5AiGdd {
  width: 73.7px;
  height: 34px;
  margin-left: 6px;
  background: url("assets/icon-global-stock-talk.svg") center no-repeat;
  background-size: 100% 100%; }
  ._2B8frl-mEyogqPIx5AiGdd._21Pk8Hzu-hyVL0m6dMC5u3 {
    background: url("assets/icon-stock-talk-tw.svg") center no-repeat; }
  ._2B8frl-mEyogqPIx5AiGdd._3EWcbyCuU1yuemvUkAGTQ7 {
    margin-right: 0; }

.L9IoFJagMjfEIIpKYwpOG._2dEBMvYVtKa5L1D3W2IJyS > ._3A9JZpgVrJUMBfbMuzw43o, .L9IoFJagMjfEIIpKYwpOG ._3A9JZpgVrJUMBfbMuzw43o._2dEBMvYVtKa5L1D3W2IJyS {
  display: block; }

.L9IoFJagMjfEIIpKYwpOG {
  position: relative;
  display: block;
  width: 26px;
  height: 26px;
  margin-left: 5px;
  background: url("assets/btn_stock switch.svg") center no-repeat;
  background-size: 13px;
  cursor: pointer; }
  .L9IoFJagMjfEIIpKYwpOG ._3A9JZpgVrJUMBfbMuzw43o {
    position: absolute;
    top: 40px;
    right: -12px;
    width: 120px;
    display: none;
    z-index: 1; }

._2tw9gMv4QQI8Q08L_vK4v7 {
  -ms-flex-item-align: end;
      align-self: flex-end;
  margin-left: 4px;
  padding: 3px 8px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 2px;
  color: #fff;
  background-color: #e03f19;
  margin-bottom: 6px;
  white-space: nowrap; }
  ._2tw9gMv4QQI8Q08L_vK4v7._3EWcbyCuU1yuemvUkAGTQ7 {
    margin-right: 0; }

._1caE8gJh7WjzHYrBVDdZ0n {
  margin-right: 12px; }
