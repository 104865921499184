/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
@keyframes _2pkO5_hYNI0hAwKkP-9AEg {
  from {
    transform: translate(0, 50px); }
  to {
    transform: translate(0, 0); } }

._2ubs2vCbc0PhERefBj0SHQ {
  color: #e03f19; }

._2RTYdydrgBkAfNZENHhjrf {
  color: #208306; }

._1utvOoSvjCMwBFHjZsuBk9 {
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 51px;
  margin-top: 10px;
  box-sizing: border-box;
  white-space: nowrap;
  /* change symbolInfo colors */
  /* stock color */
  /* stock animation */ }
  ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD {
    animation: _2pkO5_hYNI0hAwKkP-9AEg 0.2s linear;
    display: inline-block;
    will-change: transform;
    float: left; }
    ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD span {
      vertical-align: baseline; }
    ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .TKObnyUv8HwdMqnzoP98N {
      display: inline-block;
      vertical-align: middle; }
      ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .TKObnyUv8HwdMqnzoP98N h1 {
        display: inline-block; }
      ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .TKObnyUv8HwdMqnzoP98N ._1sWmcvz2rHjkpCASw4z0NV {
        font: 300 24px Lato, Arial, sans-serif;
        margin-right: 12px;
        color: #707070;
        vertical-align: middle; }
        @media screen and (min-width: 1920px) {
          ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .TKObnyUv8HwdMqnzoP98N ._1sWmcvz2rHjkpCASw4z0NV {
            font-size: 32px; } }
      ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .TKObnyUv8HwdMqnzoP98N ._37IPH93eqxrFTRl_vkLCj2 {
        font-size: 24px;
        line-height: 42px;
        margin-right: 16px;
        vertical-align: middle; }
        @media screen and (max-width: 1919px) {
          ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .TKObnyUv8HwdMqnzoP98N ._37IPH93eqxrFTRl_vkLCj2 {
            margin-right: 4px; } }
        @media screen and (min-width: 1920px) {
          ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .TKObnyUv8HwdMqnzoP98N ._37IPH93eqxrFTRl_vkLCj2 {
            font-size: 32px;
            margin-right: 12px; } }
    ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .E1ldWsyoKO0YKRnVcIzg2 {
      display: inline-block;
      margin-top: 2px;
      text-align: left;
      font: 300 20px/44px Lato, Arial, sans-serif;
      vertical-align: middle; }
      ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .E1ldWsyoKO0YKRnVcIzg2 button {
        margin-bottom: 3px; }
      ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .E1ldWsyoKO0YKRnVcIzg2 ._3QjiM4Ba7SuOIg1GsgAVtc {
        font-size: 25px;
        padding: 4px;
        border-radius: 4px; }
        @media screen and (max-width: 1919px) {
          ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .E1ldWsyoKO0YKRnVcIzg2 ._3QjiM4Ba7SuOIg1GsgAVtc {
            margin-right: 4px; } }
        @media screen and (min-width: 1920px) {
          ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .E1ldWsyoKO0YKRnVcIzg2 ._3QjiM4Ba7SuOIg1GsgAVtc {
            font-size: 32px;
            margin-right: 12px; } }
      ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .E1ldWsyoKO0YKRnVcIzg2 ._3tPL35q9Tqjch9auiNB7y3,
      ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .E1ldWsyoKO0YKRnVcIzg2 .WNW622C4moCkTouUTp4gU {
        margin-right: 8px;
        font-size: 18px; }
        @media screen and (max-width: 1919px) {
          ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .E1ldWsyoKO0YKRnVcIzg2 ._3tPL35q9Tqjch9auiNB7y3,
          ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .E1ldWsyoKO0YKRnVcIzg2 .WNW622C4moCkTouUTp4gU {
            margin-right: 4px; } }
        @media screen and (min-width: 1920px) {
          ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .E1ldWsyoKO0YKRnVcIzg2 ._3tPL35q9Tqjch9auiNB7y3,
          ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .E1ldWsyoKO0YKRnVcIzg2 .WNW622C4moCkTouUTp4gU {
            margin-right: 8px; } }
      ._1utvOoSvjCMwBFHjZsuBk9 ._2OfAfOB4wzVXcGI6RDEfCD .E1ldWsyoKO0YKRnVcIzg2 ._1I6F8IlGdR2X7rKnTAy1ue {
        font-size: 18px;
        color: #000;
        margin-right: 10px; }
  ._1utvOoSvjCMwBFHjZsuBk9 .l6rJ_Pxa6z-a07oeu57k7 {
    position: absolute;
    right: 28px;
    bottom: -24px;
    font-size: 12px;
    color: #6b6b6b; }
  ._1utvOoSvjCMwBFHjZsuBk9._3_5ktR0JjegMPFFR27RM- ._2OfAfOB4wzVXcGI6RDEfCD > .E1ldWsyoKO0YKRnVcIzg2 {
    color: #208306; }
    ._1utvOoSvjCMwBFHjZsuBk9._3_5ktR0JjegMPFFR27RM- ._2OfAfOB4wzVXcGI6RDEfCD > .E1ldWsyoKO0YKRnVcIzg2 > ._3QjiM4Ba7SuOIg1GsgAVtc {
      color: #208306;
      background-color: transparent;
      border-radius: 4px; }
  ._1utvOoSvjCMwBFHjZsuBk9.MdpkSPqdXeERnwMsHVUS8 ._2OfAfOB4wzVXcGI6RDEfCD > .E1ldWsyoKO0YKRnVcIzg2 {
    color: #d0021b; }
    ._1utvOoSvjCMwBFHjZsuBk9.MdpkSPqdXeERnwMsHVUS8 ._2OfAfOB4wzVXcGI6RDEfCD > .E1ldWsyoKO0YKRnVcIzg2 > ._3QjiM4Ba7SuOIg1GsgAVtc {
      color: #d0021b;
      background-color: transparent;
      border-radius: 4px; }
  ._1utvOoSvjCMwBFHjZsuBk9._2fazodmH_CmypxMpFUQhng ._2OfAfOB4wzVXcGI6RDEfCD > .E1ldWsyoKO0YKRnVcIzg2 {
    color: #208306; }
    ._1utvOoSvjCMwBFHjZsuBk9._2fazodmH_CmypxMpFUQhng ._2OfAfOB4wzVXcGI6RDEfCD > .E1ldWsyoKO0YKRnVcIzg2 > ._3QjiM4Ba7SuOIg1GsgAVtc {
      color: #fff;
      background-color: #208306;
      border-radius: 4px; }
  ._1utvOoSvjCMwBFHjZsuBk9._3PSqCrD4R1GkF6n_KUwrBZ ._2OfAfOB4wzVXcGI6RDEfCD > .E1ldWsyoKO0YKRnVcIzg2 {
    color: #d0021b; }
    ._1utvOoSvjCMwBFHjZsuBk9._3PSqCrD4R1GkF6n_KUwrBZ ._2OfAfOB4wzVXcGI6RDEfCD > .E1ldWsyoKO0YKRnVcIzg2 > ._3QjiM4Ba7SuOIg1GsgAVtc {
      color: #fff;
      background-color: #d0021b;
      border-radius: 4px; }

@keyframes Dk6bVdep5X_2kR9igBiTP {
  0% {
    background-color: transparent;
    color: #208306; }
  50% {
    background-color: #208306;
    color: #fff; } }
  ._1utvOoSvjCMwBFHjZsuBk9._2LZkGVGpLTwu6L6Vp_la2 ._2OfAfOB4wzVXcGI6RDEfCD > .E1ldWsyoKO0YKRnVcIzg2 > ._3QjiM4Ba7SuOIg1GsgAVtc {
    border-radius: 4px;
    animation-name: Dk6bVdep5X_2kR9igBiTP;
    animation-duration: 1s;
    animation-fill-mode: forwards; }

@keyframes _20iVeP2LlKIZeYI4m2VCXL {
  0% {
    background-color: transparent;
    color: #d0021b; }
  50% {
    background-color: #d0021b;
    color: #fff; } }
  ._1utvOoSvjCMwBFHjZsuBk9._1y3FDOb0PBfxThMwzHEaPs ._2OfAfOB4wzVXcGI6RDEfCD > .E1ldWsyoKO0YKRnVcIzg2 > ._3QjiM4Ba7SuOIg1GsgAVtc {
    border-radius: 4px;
    animation-name: _20iVeP2LlKIZeYI4m2VCXL;
    animation-duration: 1s;
    animation-fill-mode: forwards; }

._1MRYNxeiENGdBpQOI03glc {
  background-color: #d0021b;
  color: #fff; }

._3vkikHFnKemI-FR4PqKgR7 {
  background-color: #208306;
  color: #fff; }
