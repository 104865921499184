/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
._2wRob5SB2GRzDuG-lGEmV_ {
  position: absolute;
  top: 0;
  right: 0; }

.TVSs_EcSd7uK3Pj1KE1Rp {
  position: relative;
  border-bottom: 1px solid #9c9c9c;
  overflow-x: visible;
  height: 40px;
  width: 100%;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: center;
      align-items: center; }
  .TVSs_EcSd7uK3Pj1KE1Rp > img,
  .TVSs_EcSd7uK3Pj1KE1Rp h2 {
    margin-right: 12px;
    padding-bottom: 8px;
    font-size: 22px; }
  .TVSs_EcSd7uK3Pj1KE1Rp ._3EghbvTFRSkjdc7eIVDL85 > li {
    cursor: pointer;
    font-size: 18px;
    width: 30px;
    padding: 7px;
    padding-bottom: 12px;
    text-align: center; }
    .TVSs_EcSd7uK3Pj1KE1Rp ._3EghbvTFRSkjdc7eIVDL85 > li[data-active='true'] {
      border-bottom: 1px solid #e03f19; }

._3ApoRr6oxTNhh1zuJocHcL {
  top: 0;
  left: 0;
  padding-bottom: 8px;
  padding-left: 12px;
  height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  position: absolute;
  width: 100%;
  font-size: 16px;
  -ms-flex-align: center;
      align-items: center;
  border: 0;
  border-bottom: 1px solid #9c9c9c;
  background-color: #fbfbfb; }
