/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
._2GMnhR0tTLg3HX_NQ9oRxC {
  height: 438px; }

._1Xj5xDvI9DHwcknVrOutKq {
  height: 540px; }

._1-tnpR2yx3sQqM08EJXVY6 {
  display: block;
  position: relative;
  width: 360px;
  padding: 36px 20px 24px; }
  ._1-tnpR2yx3sQqM08EJXVY6 h2 {
    font-size: 20px;
    font-weight: normal;
    width: 100%;
    height: 28px;
    line-height: 28px;
    text-align: center;
    color: #2d2d2d; }
  ._1-tnpR2yx3sQqM08EJXVY6 ul {
    list-style-position: inside;
    list-style-type: none;
    margin: 24px 0 32px;
    font-size: 18px;
    line-height: 1.8; }
    ._1-tnpR2yx3sQqM08EJXVY6 ul li:before {
      content: '\2022';
      padding: 0 18px;
      font-size: 22px;
      line-height: 20px;
      vertical-align: middle; }
  ._1-tnpR2yx3sQqM08EJXVY6 p {
    text-align: justify;
    font-size: 16px;
    letter-spacing: 0.8px;
    line-height: 1.3; }
