/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
._3RsW-a3RRdJcXpIquzdHMx {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: 5; }
  ._3RsW-a3RRdJcXpIquzdHMx > div {
    top: calc(100vh - 175px - 42px - 48px);
    left: 380px;
    visibility: visible;
    will-change: transform; }
  ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG {
    position: relative;
    width: 320px;
    height: 175px;
    background-color: rgba(0, 0, 0, 0.7);
    transform: translateZ(1px);
    will-change: width, height; }
    ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG._2wytiDwBVN96G2lDgN2SJq {
      display: none; }
    ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG ._2DrE51ejf4QPcYC2ENb2Nh {
      width: 100%;
      height: 100%; }
    ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG ._1vqw8l8sFfYULU9VeHhsE2 {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center; }
      ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG ._1vqw8l8sFfYULU9VeHhsE2 > svg {
        border-radius: 8px; }
    ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG ._1IGCVvTbomTzoYTn9oizHs {
      position: absolute;
      display: block;
      width: calc(100% - 12px);
      height: calc(100% - 42px);
      bottom: 6px;
      left: 6px;
      color: #fff;
      background-color: #000;
      will-change: width, height; }
    ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG ._3QujiVWNBMiIKqMn8xJCZ7 {
      display: block;
      position: absolute;
      top: 4px;
      left: 4px;
      z-index: 3; }
      ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG ._3QujiVWNBMiIKqMn8xJCZ7 > button {
        display: inline-block;
        width: 28px;
        height: 28px;
        cursor: pointer; }
      ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG ._3QujiVWNBMiIKqMn8xJCZ7 ._2CW_fCcjzUB7eyyIp5oX1 {
        background: url("assets/icon-video-small.svg") center no-repeat; }
        ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG ._3QujiVWNBMiIKqMn8xJCZ7 ._2CW_fCcjzUB7eyyIp5oX1.cLwtJbKJmQosstCRVT0Hy {
          background: url("assets/icon-video-small-active.svg") center no-repeat; }
      ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG ._3QujiVWNBMiIKqMn8xJCZ7 ._3oP9P2gND_h1KAXcrT2eJP {
        background: url("assets/icon-video-medium.svg") center no-repeat; }
        ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG ._3QujiVWNBMiIKqMn8xJCZ7 ._3oP9P2gND_h1KAXcrT2eJP.cLwtJbKJmQosstCRVT0Hy {
          background: url("assets/icon-video-medium-active.svg") center no-repeat; }
      ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG ._3QujiVWNBMiIKqMn8xJCZ7 .UnfNNRA5WF3c4dCjgz8eH {
        background: url("assets/icon-video-large.svg") center no-repeat; }
        ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG ._3QujiVWNBMiIKqMn8xJCZ7 .UnfNNRA5WF3c4dCjgz8eH.cLwtJbKJmQosstCRVT0Hy {
          background: url("assets/icon-video-large-active.svg") center no-repeat; }
    ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG ._2t6u4rRbMATIt02xQI5w8z {
      display: block;
      position: absolute;
      width: 28px;
      height: 28px;
      right: 4px;
      top: 4px;
      background: url("assets/icon-dialog-close.svg") center no-repeat;
      background-size: 28px 28px;
      cursor: pointer;
      z-index: 3; }
    ._3RsW-a3RRdJcXpIquzdHMx ._3hTl9bI0LZOb5ABGjLECgG ._1A2e6Eb6sbw0-2t9rZGw6o {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      display: none; }
