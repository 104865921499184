/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  transition: transform 0.5s linear; }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row; }

.col {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

@keyframes _30g-oAoL0awa7TqUniXQyp {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.5; } }

.anue-dots {
  animation: _30g-oAoL0awa7TqUniXQyp 1.5s linear infinite;
  background-color: #54b5c6;
  height: 8px;
  width: 8px;
  border-radius: 8px;
  content: ' ';
  margin: 0 14px;
  position: relative; }
  .anue-dots::before {
    animation: _30g-oAoL0awa7TqUniXQyp 1s linear infinite;
    background-color: #e03f19;
    height: 8px;
    width: 8px;
    border-radius: 8px;
    content: ' ';
    top: 0;
    left: -14px;
    position: absolute; }
  .anue-dots::after {
    animation: _30g-oAoL0awa7TqUniXQyp 2s linear infinite;
    background-color: #ffb43e;
    height: 8px;
    width: 8px;
    border-radius: 8px;
    content: ' ';
    left: 14px;
    position: absolute; }
