/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
.zAnH-o4TsZJoPMJQs3hMD {
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid #d0d0d0;
  min-height: -webkit-min-content;
  min-height: -moz-min-content;
  min-height: min-content;
  max-width: 340px; }
  .zAnH-o4TsZJoPMJQs3hMD * {
    pointer-events: none; }
  .zAnH-o4TsZJoPMJQs3hMD > span {
    -ms-flex: 1;
        flex: 1;
    position: relative;
    margin-right: 8px; }
    .zAnH-o4TsZJoPMJQs3hMD > span > h3 {
      font-size: 16px;
      line-height: 23px;
      color: #333;
      -webkit-line-clamp: 2;
      max-height: 46px;
      min-height: 46px;
      overflow: hidden;
      text-overflow: ellipsis; }
    .zAnH-o4TsZJoPMJQs3hMD > span > time {
      -ms-flex-align: center;
          align-items: center;
      margin-top: 8px;
      font-size: 12px;
      color: #6b6b6b; }
      .zAnH-o4TsZJoPMJQs3hMD > span > time > ._1P5vdIi3zXgn_s3TnPuyo {
        font-weight: bolder;
        background-color: #d0021b;
        color: #fff;
        margin-right: 4px;
        padding: 2px 4px;
        border: 2px solid #d0021b;
        border-radius: 2px; }
      .zAnH-o4TsZJoPMJQs3hMD > span > time > span {
        margin: 4px; }

._3B1eEa983L33lrrXB5giQp {
  text-align: center;
  margin-top: 16px; }

._25wrbfBobhlUs_pkqTyJ3T {
  width: 70px;
  height: 70px;
  margin-right: 15px;
  background-size: cover; }
  ._25wrbfBobhlUs_pkqTyJ3T[video='1'] {
    width: 93px;
    height: 70px; }
