/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
._1IdjmWnQkk_sDC6OK8ocrq {
  position: absolute;
  right: 70px;
  top: 58px;
  font-size: 11px;
  color: #a953ae;
  font-weight: bold; }
  ._1IdjmWnQkk_sDC6OK8ocrq ._2H2hYSj6Y3oVpiABzE4pWo {
    background-color: #a953ae;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    display: inline-block;
    margin-right: 0.35em; }
  ._1IdjmWnQkk_sDC6OK8ocrq._1BJdyOd5I_FSASlda15CHw {
    color: #318757; }
    ._1IdjmWnQkk_sDC6OK8ocrq._1BJdyOd5I_FSASlda15CHw ._2H2hYSj6Y3oVpiABzE4pWo {
      background-color: #318757; }
  ._1IdjmWnQkk_sDC6OK8ocrq._3frELKcgfwW41WJUYGSIUG {
    color: #3bb3e4; }
    ._1IdjmWnQkk_sDC6OK8ocrq._3frELKcgfwW41WJUYGSIUG ._2H2hYSj6Y3oVpiABzE4pWo {
      background-color: #3bb3e4; }
