/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
@keyframes _3aJ-8AdD6jFTGHRGk8xOrW {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

._1KmMPFIlLoaLxsbvSwJ_o3 {
  -ms-flex-align: center;
      align-items: center;
  border-bottom: 1px solid #c0c0c0; }
  ._1KmMPFIlLoaLxsbvSwJ_o3 a {
    height: 100%; }

._263HlLtflw2nIP2_ee695d {
  position: relative;
  height: 41.5px; }
  ._263HlLtflw2nIP2_ee695d a {
    display: inline-block;
    width: 62px;
    height: 32px;
    line-height: 32px;
    color: #000;
    text-align: center; }

._2wTuXUcAR8kLdGOHelqzp4 {
  border-bottom: 2px solid transparent; }

._2WYcr18VD82BdZpzgCnzTH {
  border-bottom: 2px solid #e95e6f; }

._2EQCZLlMWeRXVBxCwCOCW0 {
  position: relative;
  width: 100%; }
  ._2EQCZLlMWeRXVBxCwCOCW0.qIaH-2JRN1JsqVv86QyRs {
    height: calc( 100vh - 48px - 65px - 41.5px - 24px); }
  ._2EQCZLlMWeRXVBxCwCOCW0.YjXRvPXZKLbxLAtNLDd8B {
    padding-bottom: 0;
    width: 100%;
    height: calc( 100vh - 48px - 41.5px); }
  ._2EQCZLlMWeRXVBxCwCOCW0 ._2o59Dbfv7PDtM6xaWfnDa- {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 2;
    animation: _3aJ-8AdD6jFTGHRGk8xOrW 1s; }
    ._2EQCZLlMWeRXVBxCwCOCW0 .oJGOpd0ZICiYFEAxKjOnK {
      display: block;
      position: absolute;
      visibility: hidden; }

._190X3EtUhpn80vAe9Rd8ar {
  position: relative;
  height: 24px;
  line-height: 24px; }
  ._190X3EtUhpn80vAe9Rd8ar .X8zCXPRwW0C7rTzBYz-9j {
    position: relative;
    font-size: 12px;
    color: #6b6b6b; }
