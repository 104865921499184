/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
._1oO2LAiBqVhRhsBSoMTdT2 {
  width: 100%;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex: 1;
      flex: 1; }
  ._1oO2LAiBqVhRhsBSoMTdT2 > div {
    line-height: 22px; }
    ._1oO2LAiBqVhRhsBSoMTdT2 > div > img {
      height: 16px;
      width: 16px;
      display: inline-block; }

._20fWtDp5sDtq57ZdTRqg9G {
  position: relative;
  height: 55vh;
  padding: 10px;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: start;
      align-items: flex-start;
  min-height: 0;
  -ms-flex: 1;
      flex: 1; }
  @media screen and (min-width: 1024px) {
    ._20fWtDp5sDtq57ZdTRqg9G {
      min-width: 380px; } }
  ._20fWtDp5sDtq57ZdTRqg9G > ul {
    -ms-flex: 1;
        flex: 1;
    overflow-y: auto;
    padding-bottom: 100px;
    width: 100%; }
  @media screen and (max-width: 1023px) {
    ._20fWtDp5sDtq57ZdTRqg9G {
      display: none; } }
