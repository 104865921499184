/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
.PW3BwtsMYVU6fhdB7Ih1M {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  min-width: 1024px;
  height: 48px;
  background-color: #fff;
  border-bottom: 1px solid #c0c0c0; }
  .PW3BwtsMYVU6fhdB7Ih1M::after {
    content: '';
    background: linear-gradient(to right, #54b5c6 60%, #e03f19 60%, #e03f19 90%, #ffb43e 90%);
    position: absolute;
    height: 2px;
    right: 0;
    left: 0;
    top: 0; }
  .PW3BwtsMYVU6fhdB7Ih1M ._2wowdaA4Bm1C2i09cuto4h {
    width: 1024px;
    height: 100%;
    margin: 0 auto; }
    .PW3BwtsMYVU6fhdB7Ih1M ._2wowdaA4Bm1C2i09cuto4h > * {
      display: inline-block;
      position: relative;
      float: left; }
    .PW3BwtsMYVU6fhdB7Ih1M ._2wowdaA4Bm1C2i09cuto4h ._3rxfnRhJsPlRGBLYPd2kLA {
      width: 94px;
      height: 31px;
      margin-top: 11px;
      background: url("assets/icon-cnyes-title.svg") center no-repeat;
      background-size: 94px 31px; }
  .PW3BwtsMYVU6fhdB7Ih1M ._1yHkqVmir_PduPuXyzGk96 {
    min-width: 80px;
    line-height: 28px;
    margin-top: 19px;
    color: #1e1e1e;
    font-size: 20px; }
  .PW3BwtsMYVU6fhdB7Ih1M ._34JxKy0yK85Zn4HZMS228f {
    width: 1px;
    height: 20px;
    min-width: 1px;
    background-color: #000;
    margin: 23px 10px auto; }

.PY9wywUToNZakXCWKhnpA {
  width: 100%;
  height: 100vh;
  padding-bottom: 48px;
  overflow-y: scroll; }
  .PY9wywUToNZakXCWKhnpA ._1UVOPzzLWAd0mo1DgMuyfK {
    display: block;
    position: relative;
    width: 1024px;
    margin: 0 auto;
    padding: 32px 0;
    text-align: justify; }
    .PY9wywUToNZakXCWKhnpA ._1UVOPzzLWAd0mo1DgMuyfK h1,
    .PY9wywUToNZakXCWKhnpA ._1UVOPzzLWAd0mo1DgMuyfK h2 {
      font-size: 18px;
      font-weight: normal;
      width: 100%;
      height: 28px;
      line-height: 1.8;
      text-align: left;
      color: #2d2d2d; }
    .PY9wywUToNZakXCWKhnpA ._1UVOPzzLWAd0mo1DgMuyfK h1 {
      font-size: 24px;
      line-height: 1.3; }
    .PY9wywUToNZakXCWKhnpA ._1UVOPzzLWAd0mo1DgMuyfK p {
      margin: 24px 0;
      font-size: 18px;
      line-height: 1.8;
      text-align: justify;
      color: #333; }
