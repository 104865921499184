/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
._3br6mkGzOKKsiL17qWIUei {
  position: absolute;
  left: 0;
  width: 100%;
  overflow-y: scroll;
  border: 1px solid #999999;
  z-index: 10;
  background-color: #fff;
  transform: translateZ(0); }
