/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
.tpS7rB08LA8m8NnmMOfrU {
  position: relative;
  height: 100%;
  -ms-flex: 0 0 300px;
      flex: 0 0 300px;
  text-align: left;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .tpS7rB08LA8m8NnmMOfrU._25yOI_CFye_tgvA9-ib3z3 {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.95);
    z-index: 101; }
  ._30Yni8LvTKB7iSsfOCy2hG {
    min-width: 300px; }
    ._30Yni8LvTKB7iSsfOCy2hG._25yOI_CFye_tgvA9-ib3z3 {
      top: 10px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      position: fixed;
      left: 0;
      right: 0;
      -ms-flex-align: center;
          align-items: center; }
    ._30Yni8LvTKB7iSsfOCy2hG ._1PvpTWo7IwLMct8D4Tuo2E {
      position: relative;
      width: 300px;
      height: 35px;
      background-color: #eee;
      border: 1px solid #1e1e1e;
      -ms-flex-align: center;
          align-items: center;
      padding-left: 8px; }
      ._30Yni8LvTKB7iSsfOCy2hG ._1PvpTWo7IwLMct8D4Tuo2E._1zdpWjMZdJTyFqVZcq5fit {
        height: 20px;
        width: 20px; }
      ._30Yni8LvTKB7iSsfOCy2hG ._1PvpTWo7IwLMct8D4Tuo2E._25yOI_CFye_tgvA9-ib3z3 {
        width: 80%;
        margin: 0 10px; }
      ._30Yni8LvTKB7iSsfOCy2hG ._1PvpTWo7IwLMct8D4Tuo2E ._1NqwsE9mmHVdMy3LwBrHCM {
        box-sizing: border-box;
        display: block;
        width: 100%;
        padding-left: 16px;
        border: 0;
        font-size: 14px;
        font-family: "Open Sans", "Arial", "Hiragino Sans GB", "Pingfang TC", "Lantinghei TC", "Wenquanyi Micro Hei", "WenQuanYi Zen Hei", "ST Heiti", "WenQuanYi Zen Hei Sharp", "Microsoft YaHei", "Microsoft Jhenghei", SimHei, sans-serif;
        color: #1e1e1e;
        z-index: 15; }
        ._30Yni8LvTKB7iSsfOCy2hG ._1PvpTWo7IwLMct8D4Tuo2E ._1NqwsE9mmHVdMy3LwBrHCM::-ms-clear {
          display: none; }
        ._30Yni8LvTKB7iSsfOCy2hG ._1PvpTWo7IwLMct8D4Tuo2E ._1NqwsE9mmHVdMy3LwBrHCM::-webkit-input-placeholder {
          color: #1e1e1e; }
      ._30Yni8LvTKB7iSsfOCy2hG ._1PvpTWo7IwLMct8D4Tuo2E ._18zwKke1PHqvxBSmYcsKNH {
        position: absolute;
        top: 14px;
        right: 14px;
        display: block;
        width: 10px;
        height: 10px;
        outline: 0;
        background: url("assets/icon-search-cancel.svg") center no-repeat;
        cursor: pointer;
        z-index: 15; }
      ._30Yni8LvTKB7iSsfOCy2hG ._1PvpTWo7IwLMct8D4Tuo2E ._270Aep46N0zyvFHUJcnKma {
        display: block;
        background: url("assets/icon-search-loading.svg") center no-repeat; }
