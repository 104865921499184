/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
._2ZLz0aH7XbTQEVvtUnfUty {
  overflow: auto;
  height: 100%; }

._26fClyq0ub3ijG-N-i7I4O {
  width: 100%;
  min-height: 70vh;
  margin: 10px auto; }
  ._26fClyq0ub3ijG-N-i7I4O > div {
    min-height: 40px;
    -ms-flex-align: center;
        align-items: center;
    border-bottom: 1px solid #c2c2c2; }
    ._26fClyq0ub3ijG-N-i7I4O > div span {
      width: 105px;
      padding: 8px;
      text-align: left; }
      ._26fClyq0ub3ijG-N-i7I4O > div span:nth-child(even) {
        -ms-flex: 3;
            flex: 3;
        line-height: 1.3; }
      ._26fClyq0ub3ijG-N-i7I4O > div span:nth-child(odd) {
        font-weight: bold; }
