/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  box-sizing: border-box; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%; }

*,
*:before,
*:after {
  box-sizing: inherit; }

a,
a:link,
a:active,
a:visited {
  text-decoration: none; }

input {
  border: none; }

main, article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

input,
select,
button {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1; }

/* Remove the stupid outer glow in Webkit */
input:focus {
  outline: 0; }

/* Box Sizing Reset
-----------------------------------------------*/
/* All of our custom controls should be what we expect them to be */
input {
  -webkit-appearance: none;
  border-radius: 0; }

/* Make webkit render the search input like a normal text field */
input[type=search] {
  -webkit-appearance: none;
  -webkit-box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none; }

/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
html,
body {
  -webkit-overflow-scrolling: touch; }

html {
  height: 100%; }

body {
  position: relative;
  height: 100%;
  background: #fff;
  color: #333;
  text-rendering: optimizeLegibility;
  font-smooth: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans", "Arial", "Hiragino Sans GB", "Pingfang TC", "Lantinghei TC", "Wenquanyi Micro Hei", "WenQuanYi Zen Hei", "ST Heiti", "WenQuanYi Zen Hei Sharp", "Microsoft YaHei", "Microsoft Jhenghei", SimHei, sans-serif;
  font-weight: 400; }

a,
button {
  cursor: pointer; }

input[type='submit'], input[type='button'] {
  cursor: pointer; }

a {
  -ms-touch-action: manipulation;
      touch-action: manipulation; }

*:focus {
  outline: 0; }
