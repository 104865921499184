/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
.button-2-lC3gh4-.button-2ioYhFEY-.apply-common-tooltip.isInteractive-20uLObIc- {
  padding-right: 12px; }

._1SlVlKgADlbhF1vk5sr93V {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }

._2VyWxuGG6NxMN5oyTm2J6T {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex: 1;
      flex: 1;
  height: 100%; }
  ._3yDQol9SwaQp5e_gUYyK0S {
    position: absolute;
    top: 50%;
    margin-top: 24px;
    font-size: 12px;
    color: #979797;
    width: 100%;
    height: 30px;
    left: 0;
    text-align: center; }
  ._3XMJZyZ8HovcKz4RhsU53T {
    position: absolute;
    pointer-events: none;
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    transition-delay: 0.8s;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    ._3XMJZyZ8HovcKz4RhsU53T > svg {
      position: absolute;
      transition: opacity 0.8s ease-out, transform 0.8s ease-out; }
    ._3XMJZyZ8HovcKz4RhsU53T p {
      margin-top: 12px;
      font-size: 10px;
      color: #e03f19; }
    ._2pXvwBJeCenmDeNW610UMC {
      background-color: transparent; }
      ._2pXvwBJeCenmDeNW610UMC p {
        display: none; }
      ._2pXvwBJeCenmDeNW610UMC > svg {
        left: 40px;
        bottom: 55px;
        width: 120px;
        height: 30px; }
    ._2JAao-AYnQa_6mRjIsswZb {
      background-color: transparent; }
      ._2JAao-AYnQa_6mRjIsswZb p {
        display: none; }
      ._2JAao-AYnQa_6mRjIsswZb > svg {
        left: -10px;
        bottom: 55px;
        width: 120px;
        height: 30px; }
  ._2BeyYuRx3CjYc2TZyRPCrc {
    background-color: #fff; }
    ._2BeyYuRx3CjYc2TZyRPCrc > svg {
      left: 50%;
      bottom: 50%;
      margin-left: -90px;
      margin-top: -25px;
      width: 180px;
      height: 50px; }
  ._3pt4f5YdPIOhb41IwcCO4f {
    -ms-flex: 1;
        flex: 1;
    height: 100%;
    border: 1px solid #d9dadb; }
  ._2VyWxuGG6NxMN5oyTm2J6T ._1fsNmiperZe_KXM091uf-l {
    position: absolute;
    right: 70px;
    top: 58px;
    font-size: 11px;
    color: #a953ae;
    font-weight: bold; }
    ._2VyWxuGG6NxMN5oyTm2J6T ._1fsNmiperZe_KXM091uf-l ._1dNC6BZUnsaZGYzkVmHnBM {
      background-color: #a953ae;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      display: inline-block;
      margin-right: 0.35em; }
    ._2VyWxuGG6NxMN5oyTm2J6T ._1fsNmiperZe_KXM091uf-l._1NcApwb_yGzP9fYzM7GPpI {
      color: #318757; }
      ._2VyWxuGG6NxMN5oyTm2J6T ._1fsNmiperZe_KXM091uf-l._1NcApwb_yGzP9fYzM7GPpI ._1dNC6BZUnsaZGYzkVmHnBM {
        background-color: #318757; }
    ._2VyWxuGG6NxMN5oyTm2J6T ._1fsNmiperZe_KXM091uf-l.Q-D4OQTdcTIaelsv_4_l4 {
      color: #3bb3e4; }
      ._2VyWxuGG6NxMN5oyTm2J6T ._1fsNmiperZe_KXM091uf-l.Q-D4OQTdcTIaelsv_4_l4 ._1dNC6BZUnsaZGYzkVmHnBM {
        background-color: #3bb3e4; }

.YxeZg3maIR-7NY3LV0Noy {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
