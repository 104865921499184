@charset "UTF-8";
/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
.u1Cp-Krl63_4kBKuSJGhr {
  min-width: auto;
  height: 100%;
  -ms-flex: 1;
      flex: 1; }
  @media screen and (max-width: 768px) {
    .u1Cp-Krl63_4kBKuSJGhr {
      max-width: 40px; } }

._2zmMjX2t2DU8HnIq1AHws0 {
  position: relative;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  font-size: 16px;
  text-align: right;
  line-height: 30px;
  color: #1e1e1e;
  padding-right: 0;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding-top: 2px; }
  ._2zmMjX2t2DU8HnIq1AHws0._90msv4FyLHYImZFOBDxGH {
    -ms-flex-pack: start;
        justify-content: flex-start;
    margin-left: 10px; }
  @media screen and (min-width: 768px) {
    ._2zmMjX2t2DU8HnIq1AHws0 {
      padding-right: 8px;
      padding-top: 0; } }
  ._2zmMjX2t2DU8HnIq1AHws0 > *:not(._24D5WDqVxGlacEd0MzkKsJ) {
    display: inline-block;
    position: relative;
    z-index: 20; }
  ._2zmMjX2t2DU8HnIq1AHws0 ._1Yhc0x_Rr0DZ9vzH5wPpGC {
    width: 1px;
    height: 20px;
    background-color: #1e1e1e;
    margin: auto 10px; }
  ._2zmMjX2t2DU8HnIq1AHws0 ._2iGFwYS-EdjeAh0HsWEd5m {
    width: 28px;
    height: 28px;
    background: url("assets/icon-dropdown-menu.svg") center no-repeat;
    cursor: pointer; }
  ._2zmMjX2t2DU8HnIq1AHws0 ._24D5WDqVxGlacEd0MzkKsJ {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 120px;
    padding-top: 52px;
    z-index: 2; }
    ._2zmMjX2t2DU8HnIq1AHws0 ._24D5WDqVxGlacEd0MzkKsJ._2oRpCQLrGeuN0rg-oz_S0z {
      display: block; }
  @media screen and (max-width: 768px) {
    ._2zmMjX2t2DU8HnIq1AHws0 ._1Yhc0x_Rr0DZ9vzH5wPpGC,
    ._2zmMjX2t2DU8HnIq1AHws0 ._2iGFwYS-EdjeAh0HsWEd5m,
    ._2zmMjX2t2DU8HnIq1AHws0 ._24D5WDqVxGlacEd0MzkKsJ,
    ._2zmMjX2t2DU8HnIq1AHws0 ._1h41E13UHtawQhgjHt3hqu {
      display: none; } }

._1BU8GIFaPPanGhgYNqaJrT {
  position: relative;
  cursor: pointer; }

._34TiORo7MYonQnmlgvXv9g {
  width: 30px;
  height: 30px;
  position: relative;
  padding-left: 41px;
  cursor: pointer; }
  ._34TiORo7MYonQnmlgvXv9g:before {
    content: '';
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0;
    top: 0;
    background: url("assets/icon-login-avatar.svg") center no-repeat;
    background-size: 30px 30px; }
  @media screen and (min-width: 768px) {
    ._34TiORo7MYonQnmlgvXv9g {
      width: auto;
      height: auto; } }

._2EQ85FfnKOvjP3Nmr-yRx7 {
  position: relative;
  padding-left: 41px;
  cursor: pointer;
  z-index: 2;
  max-width: 300px;
  overflow: visible;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 30px;
  min-height: 30px;
  max-height: 30px; }
  ._2EQ85FfnKOvjP3Nmr-yRx7 ._2xwg7KCL1QZPx3d4eDtvng {
    display: block;
    position: absolute;
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    left: 0;
    top: 0;
    border-radius: 50%;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat; }

._34TiORo7MYonQnmlgvXv9g._1-2-Dn8NcazdtnGDTFySHs::after,
._2EQ85FfnKOvjP3Nmr-yRx7._1-2-Dn8NcazdtnGDTFySHs::after {
  content: '•';
  position: absolute;
  top: -10px;
  left: 22px;
  font-size: 24px;
  color: #e03f19; }

._390Y-hhHWqz_Rg8EjWnuCQ main {
  width: 100%;
  height: 100%;
  min-width: 100%; }

.NJ7OEE0yPEhwOLqwEHcaT {
  text-align: left; }
  .NJ7OEE0yPEhwOLqwEHcaT div {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top; }

._1h41E13UHtawQhgjHt3hqu a {
  color: #1e1e1e; }

@media screen and (max-width: 768px) {
  .xWiWtq7ucuYJPGxZz64R8,
  ._2pclOgCSM2v2kJL1-5nvTD {
    display: none; } }
