/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
.JSKguuVwm-1ke_HtWdVJP {
  max-height: calc(100% - 40px);
  padding-bottom: 30px;
  overflow-x: hidden;
  transform: translateZ(0); }
  @media screen and (min-width: 1024px) {
    .JSKguuVwm-1ke_HtWdVJP {
      overflow-y: auto; } }

.AKqznlc5PrnDOZiTZhksK {
  display: block;
  padding: 0;
  overflow-x: hidden;
  margin-top: 16px;
  background-color: #fbfbfb;
  -ms-flex-positive: 0;
      flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-preferred-size: 45%;
      flex-basis: 45%;
  transition: flex-basis 0.5s ease;
  transition: flex-basis 0.5s ease, -ms-flex-preferred-size 0.5s ease; }
  @media screen and (min-width: 1024px) {
    .AKqznlc5PrnDOZiTZhksK {
      height: calc(100% - 54vh);
      max-height: calc(100% - 54vh);
      overflow-y: hidden;
      padding-left: 20px;
      max-width: 370px;
      min-width: 370px; } }
  .WQpQsmNuXnuUeYcC6bfDr {
    -ms-flex-preferred-size: 70px;
        flex-basis: 70px; }
    .WQpQsmNuXnuUeYcC6bfDr::before {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      max-width: 380px;
      height: 13px;
      background-color: #fff;
      content: '';
      z-index: 1; }
  .AKqznlc5PrnDOZiTZhksK ._1DAcy90cs3J6tk4oy9Wh3o {
    position: relative;
    display: inline-block;
    height: 40px;
    border-bottom: 1px solid #9c9c9c; }
    @media screen and (max-width: 1023px) {
      .AKqznlc5PrnDOZiTZhksK ._1DAcy90cs3J6tk4oy9Wh3o {
        width: 100%; } }
  .AKqznlc5PrnDOZiTZhksK .Y_UBndSlbd6pQQ4hYkSRW li {
    display: inline-block;
    width: 100px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    color: #000;
    cursor: pointer; }
  .AKqznlc5PrnDOZiTZhksK .Y_UBndSlbd6pQQ4hYkSRW a {
    display: block;
    height: 100%;
    padding: 8px 10px; }
    .AKqznlc5PrnDOZiTZhksK .Y_UBndSlbd6pQQ4hYkSRW a.jTReBkMLuMJULrRhV2SUo {
      position: relative; }
      .AKqznlc5PrnDOZiTZhksK .Y_UBndSlbd6pQQ4hYkSRW a.jTReBkMLuMJULrRhV2SUo::before {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #e95e6f;
        content: ''; }
  .AKqznlc5PrnDOZiTZhksK ._1M8KyvG95eSQc9F1CrIxpm {
    position: absolute;
    bottom: -2px;
    width: 100px;
    height: 2px;
    border: 0;
    margin: 0;
    background-color: #e95e6f;
    transition: left 0.3s ease-out; }
    .AKqznlc5PrnDOZiTZhksK ._2D4yXUOyTW7y2MRfxg1QEO {
      left: 0; }
    .AKqznlc5PrnDOZiTZhksK ._3SVbWu4x_QToAMXSlKIvgH {
      left: 100px; }
    .AKqznlc5PrnDOZiTZhksK ._20lnVPxMjR924uownW39K2 {
      left: 200px; }
  .AKqznlc5PrnDOZiTZhksK ._2X0b7Ty3GXDaMAknbR4s4l {
    width: 24px;
    height: 24px;
    background: url("assets/icon-collapse.svg") center no-repeat;
    -ms-transform: translateX(10px);
        transform: translateX(10px);
    vertical-align: middle;
    cursor: pointer; }
    @media screen and (max-width: 1023px) {
      .AKqznlc5PrnDOZiTZhksK ._2X0b7Ty3GXDaMAknbR4s4l {
        display: none; } }
    @media screen and (min-width: 1024px) {
      .AKqznlc5PrnDOZiTZhksK ._2X0b7Ty3GXDaMAknbR4s4l {
        display: inline-block; } }
    .AKqznlc5PrnDOZiTZhksK ._9xXV6fVTyUnMfx0zelSFm {
      -ms-transform: translateX(10px) rotate(180deg);
          transform: translateX(10px) rotate(180deg); }
  .AKqznlc5PrnDOZiTZhksK main {
    width: 100%;
    min-width: 0;
    height: calc(100% - 50px);
    max-height: calc(100% - 50px); }
