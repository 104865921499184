@charset "UTF-8";
/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
.ad-block-bottom-banner {
  width: 100%;
  height: 90px;
  text-align: center; }

.ad-block-chatroom {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  overflow: hidden;
  text-align: left; }
  .ad-block-chatroom > * {
    display: block; }
  .ad-block-chatroom iframe {
    width: 100%; }

.ad-block-search-bar {
  -ms-flex: 0 0 145px;
      flex: 0 0 145px;
  width: 145px;
  height: 35px;
  margin: 7px 2px 7px 25px; }
  .ad-block-search-bar + .ad-block-search-bar {
    margin-left: 0; }

.video-ad-wrapper {
  display: none; }

.video-ad-container {
  height: 205px !important; }
  .video-ad-container:before {
    content: '廣告';
    position: absolute;
    top: 5px;
    left: 6px;
    padding: 3px;
    border-radius: 3px;
    color: #fff;
    background-color: #f3b459; }

.ad-live-steam-block {
  position: absolute;
  width: 308px;
  height: 168px;
  bottom: 6px;
  left: 6px;
  border: 1px solid #c0c0c0;
  background-color: rgba(0, 0, 0, 0.7); }
  .ad-live-steam-block iframe {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important; }

.ad-block-news-item {
  width: 340px;
  min-height: 100px;
  padding: 5px 0;
  border-bottom: 1px solid #d0d0d0;
  background-position: center; }
