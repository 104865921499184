/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
._3RCAdsyUiIyL3O6zv5mBWn {
  position: relative;
  width: 100%;
  border: 1px solid #1e1e1e;
  background-color: #fff;
  text-align: center;
  z-index: 1; }
  ._3RCAdsyUiIyL3O6zv5mBWn a {
    display: block;
    width: 100%;
    padding: 10px 25px;
    border-top: 1px solid #1e1e1e;
    background-color: #fff;
    color: #e03f19;
    font-size: 17px;
    line-height: 17px;
    /* arrow */ }
    ._3RCAdsyUiIyL3O6zv5mBWn a > img {
      width: 100%; }
    ._3RCAdsyUiIyL3O6zv5mBWn a._1u1ZnmR1I_kW-eE6q_Qoly {
      padding: 8px 10px; }
    ._3RCAdsyUiIyL3O6zv5mBWn a:hover {
      background-color: #fbe8e3; }
    ._3RCAdsyUiIyL3O6zv5mBWn a:first-child {
      border-top: 0; }
      ._3RCAdsyUiIyL3O6zv5mBWn a:first-child::before, ._3RCAdsyUiIyL3O6zv5mBWn a:first-child::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        -ms-transform: translateY(-100%);
            transform: translateY(-100%); }
      ._3RCAdsyUiIyL3O6zv5mBWn a:first-child::before {
        top: 0;
        right: 6px;
        border-color: transparent transparent #1e1e1e;
        border-width: 11.5px; }
      ._3RCAdsyUiIyL3O6zv5mBWn a:first-child::after {
        top: 1px;
        right: 6.5px;
        border-color: transparent transparent #fff;
        border-width: 11px; }
      ._3RCAdsyUiIyL3O6zv5mBWn a:first-child:hover::after {
        border-color: transparent transparent #fbe8e3; }
    ._3RCAdsyUiIyL3O6zv5mBWn a ._3jBVSyydrE7l0GAAsJChfz {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-bottom: 12px solid;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent; }
