/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
.QRNXfRzy-8pmTDF5BcQ_Y {
  min-height: 45px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  padding: 14px;
  border-bottom: 1px solid #d8d8d8; }
  .QRNXfRzy-8pmTDF5BcQ_Y ._29eyoQp4EU1ix2TptjGPJP {
    margin-right: 10px; }
  .QRNXfRzy-8pmTDF5BcQ_Y ._3kwSh6DblKRc4UIycG67WO {
    -ms-flex: 1;
        flex: 1; }
  .QRNXfRzy-8pmTDF5BcQ_Y ._2HGdKa6bFBwYPs5YTUGXRp {
    float: right;
    color: #909090;
    font-size: 12px;
    line-height: 17px; }
  .QRNXfRzy-8pmTDF5BcQ_Y mark {
    color: #d0021b;
    background-color: transparent; }
  .QRNXfRzy-8pmTDF5BcQ_Y[data-selected='true'] {
    background-color: #eee; }
