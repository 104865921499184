/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
body {
  overflow-y: hidden; }

main {
  height: 100vh;
  width: 100vw;
  min-width: 1264px; }

._31NcFz8mudwdpsc3GvR2bs {
  position: absolute;
  top: 108px;
  left: -10px;
  background-color: #53b5c6;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  border-radius: 50%; }
  ._31NcFz8mudwdpsc3GvR2bs:hover {
    cursor: pointer;
    background-color: #2d94a7; }

._1ymWPmk-jHLYweS_cDJ4LS {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: calc(100vh - 48px);
  width: 100%;
  max-width: 100vw; }
  ._1ymWPmk-jHLYweS_cDJ4LS > aside {
    display: -ms-flexbox;
    display: flex;
    transition: width 0.5s ease-out;
    width: 380px;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    position: relative;
    height: 100%;
    overflow-x: visible; }
    ._1ymWPmk-jHLYweS_cDJ4LS > aside._1_cktbOpNzGpugC95FZTPS {
      display: -ms-flexbox;
      display: flex;
      min-height: 0;
      -ms-flex-order: 1;
          order: 1;
      -ms-flex-direction: column;
          flex-direction: column;
      background-color: #fbfbfb; }
    ._1ymWPmk-jHLYweS_cDJ4LS > aside._2Idg51iJKtU6iTVtzKm6XH {
      -ms-flex-order: 4;
          order: 4;
      width: 315px;
      max-height: 100%;
      position: relative; }
      ._1ymWPmk-jHLYweS_cDJ4LS > aside._2Idg51iJKtU6iTVtzKm6XH > ._1P7k3-zzL67K8sCvi-TuTq {
        padding: 10px;
        position: relative;
        width: 100%; }
  ._1ymWPmk-jHLYweS_cDJ4LS > ._33IvpNA49TLzNYlB7-umzn {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    transform: translateZ(0);
    -ms-flex-order: 3;
        order: 3;
    padding: 0 10px;
    position: relative;
    background-color: #fff;
    box-shadow: -2px 0 3px rgba(0, 0, 0, 0.1); }
    @media screen and (min-width: 1366px) {
      ._1ymWPmk-jHLYweS_cDJ4LS > ._33IvpNA49TLzNYlB7-umzn {
        padding: 0 20px; } }
