/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
._3gAPmarHLKHIWRfbb6BnSC {
  border-radius: 3px;
  font-size: 12px;
  height: 20px;
  padding: 0 0 1px 2px;
  background: #4267b2;
  border: 1px solid #4267b2;
  color: #fff;
  margin: 0;
  white-space: nowrap;
  display: inline-block;
  zoom: 1; }
  ._3gAPmarHLKHIWRfbb6BnSC:hover {
    background: #365899;
    border: 1px solid #365899; }

.frEKfWBBRtF2-8IP-A928 {
  background: url("assets/icon-facebook.svg") center no-repeat;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle; }

.pW6xsAyncCOz9FRQJiQQi {
  font-family: bold 12px Helvetica, Arial, sans-serif;
  vertical-align: middle;
  padding: 0 4px; }
