/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
._2X3NLBnvOf1QbCVTlEuDh5 {
  padding: 12px 16px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #e2e2e2;
  animation-fill-mode: forwards;
  width: 100%;
  -ms-flex-align: center;
      align-items: center;
  padding-right: 0;
  cursor: pointer; }
  ._2X3NLBnvOf1QbCVTlEuDh5[data-enable='false'] {
    opacity: 0.3;
    transition: transform 0.3s;
    pointer-events: none;
    overflow: hidden; }
  ._2X3NLBnvOf1QbCVTlEuDh5 img {
    margin-left: 4px;
    margin-right: 6px;
    visibility: hidden;
    height: 10px;
    width: 10px; }
  ._2X3NLBnvOf1QbCVTlEuDh5:not(._1IEnhhiVDeR-pnJfi96bwe):hover img {
    visibility: visible;
    cursor: pointer; }

._2Z6I_HwhOlWYgzELdgDV2y {
  min-height: 100px;
  -ms-flex-align: end;
      align-items: flex-end;
  cursor: grabbing; }

._1IEnhhiVDeR-pnJfi96bwe {
  opacity: 0.7;
  border: 1px dashed #c0c0c0; }

._1g0kbIoi1wyt23wFyGSnln {
  margin-left: 10px; }

._1UOt8jbd4Q7Ccou2jlBJQW {
  width: 60px;
  font-size: 14px;
  padding: 4px;
  text-align: right; }

._k8kayShpPdvdRjRWdZAM {
  -ms-flex: 1;
      flex: 1;
  line-height: 18px;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

._3dHKDNOjizV9EyP-EXcA13 {
  color: #d0021b; }

._23m6wImqAKAMwlHf2vBhxR {
  color: #208306; }

.E5bfz9KZ6EkYfBjjR8vOl {
  background-color: #d0021b;
  color: #fff; }

._1fMtEnrmkJ10_w3RmYCzWM {
  background-color: #208306;
  color: #fff; }
