/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
._1VKpnoFNjCCht598VG-s3L {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  min-width: 1024px;
  height: 48px;
  background-color: #fff;
  border-bottom: 1px solid #c0c0c0; }
  ._1VKpnoFNjCCht598VG-s3L::after {
    content: '';
    background: linear-gradient(to right, #54b5c6 60%, #e03f19 60%, #e03f19 90%, #ffb43e 90%);
    position: absolute;
    height: 2px;
    right: 0;
    left: 0;
    top: 0; }
  ._1VKpnoFNjCCht598VG-s3L ._1gXYFOLAwLORHl0-IPVm-8 {
    width: 1024px;
    height: 100%;
    margin: 0 auto; }
    ._1VKpnoFNjCCht598VG-s3L ._1gXYFOLAwLORHl0-IPVm-8 > * {
      display: inline-block;
      position: relative;
      float: left; }
    ._1VKpnoFNjCCht598VG-s3L ._1gXYFOLAwLORHl0-IPVm-8 ._3-x2iFsyq2173eZ_lMJW0T {
      width: 94px;
      height: 31px;
      margin-top: 11px;
      background: url("assets/icon-cnyes-title.svg") center no-repeat;
      background-size: 94px 31px; }
  ._1VKpnoFNjCCht598VG-s3L ._2KAxS_eFqSQG7Ehi_NaK5Z {
    min-width: 80px;
    line-height: 28px;
    margin-top: 19px;
    color: #1e1e1e;
    font-size: 20px; }
  ._1VKpnoFNjCCht598VG-s3L ._1rrLkXl8Grol9nt5jcOXMf {
    width: 1px;
    height: 20px;
    min-width: 1px;
    background-color: #000;
    margin: 23px 10px auto; }

._3DJjo2AMUuTYNxqllfIa9s {
  width: 100%;
  height: 100vh;
  padding-bottom: 48px;
  overflow-y: scroll; }
  ._3DJjo2AMUuTYNxqllfIa9s ._7848WyCCQGFc981HJRoCz {
    display: block;
    position: relative;
    width: 1024px;
    margin: 0 auto;
    padding: 32px 0;
    text-align: justify; }
    ._3DJjo2AMUuTYNxqllfIa9s ._7848WyCCQGFc981HJRoCz h1,
    ._3DJjo2AMUuTYNxqllfIa9s ._7848WyCCQGFc981HJRoCz h2 {
      font-size: 18px;
      font-weight: bold;
      width: 100%;
      height: 28px;
      line-height: 1.8;
      text-align: left;
      color: #2d2d2d; }
    ._3DJjo2AMUuTYNxqllfIa9s ._7848WyCCQGFc981HJRoCz h1 {
      font-size: 24px;
      line-height: 1.3; }
    ._3DJjo2AMUuTYNxqllfIa9s ._7848WyCCQGFc981HJRoCz p {
      margin: 24px 0;
      font-size: 18px;
      line-height: 1.8;
      text-align: justify;
      color: #333; }
