._3v8CJFWdTN1JrwNay4YC-v {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
  vertical-align: middle; }
  .r46KAnQMsLN-kFyJB6QJQ {
    background: url("assets/icon-star-on.svg") center no-repeat; }
  ._2uAFzRG1N1AmGVczjJ2NkT {
    background: url("assets/icon-star-off.svg") center no-repeat; }
