/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors'
@import './variables/components'
@import './themes/default'
*/
@keyframes _3NB-2o_LIcmn6V2xIG5Z6A {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.MXx3IY8DdLD5PiWyrx-Z0 {
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 900;
  background-color: rgba(0, 0, 0, 0.4);
  animation: _3NB-2o_LIcmn6V2xIG5Z6A 0.2s linear forwards; }

._29MdbHqOTc1hf0OrAMmocG {
  display: block;
  position: absolute;
  width: auto;
  height: auto;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  background-color: #fff; }
  ._29MdbHqOTc1hf0OrAMmocG ._1y-kAVDzuLqP3SUHkEnyId {
    display: block;
    position: absolute;
    top: 14px;
    right: 14px;
    width: 13px;
    height: 13px;
    background: url("assets/icon-modal-close.svg") center no-repeat;
    background-size: 13px 13px;
    cursor: pointer;
    z-index: 999; }
